// =========================
// Slide Animations
// =========================
const slideUp = (selector, duration = 0.3) => {
    // Normalize the selector into a DOM element
    const target = selector instanceof HTMLElement
        ? selector
        : typeof selector === "string"
        ? document.querySelector(selector)
        : null;

    if (!target) {
        console.error("Invalid target for slideUp:", selector);
        return;
    }

    gsap.to(target, {
        height: 0,
        duration: duration,
        ease: "power4.inOut",
        onComplete: () => {
            target.style.display = "none"; // Hide the element after animation
        },
    });
};

const slideDown = (selector, duration = 0.3) => {
    // Normalize the selector into a DOM element
    const target = selector instanceof HTMLElement
        ? selector
        : typeof selector === "string"
        ? document.querySelector(selector)
        : null;

    if (!target) {
        console.error("Invalid target for slideUp:", selector);
        return;
    }

    target.style.display = "flex"; // Ensure the element is visible
    target.style.flexDirection = "column";
    const height = target.scrollHeight; // Calculate full height
    gsap.fromTo(
        target,
        { height: 0 },
        {
            height: height,
            duration: duration,
            ease: "power4.inOut",
            onComplete: () => {
                target.style.height = "auto"; // Reset height for flexibility
            },
        }
    );
};

const slideToggle = (selector, duration = 0.3) => {
    // Normalize the selector into a DOM element
    const target = selector instanceof HTMLElement
        ? selector
        : typeof selector === "string"
        ? document.querySelector(selector)
        : null;

    if (!target) {
        console.error("Invalid target for slideUp:", selector);
        return;
    }

    if (window.getComputedStyle(target).display === "none") {
        slideDown(target, duration);
    } else {
        slideUp(target, duration);
    }
};



// =========================
// GSAP Animations
// =========================
const initSlideAnimation = (element, x = 0, y = 0) => {
    const startPosition = element.classList.contains("center")
        ? "center 80%"
        : element.classList.contains("bottom")
        ? "bottom 80%"
        : "top 80%";

    const animation = gsap.from(element, {
        scrollTrigger: {
            start: startPosition,
            end: "top",
            trigger: element,
            toggleActions: "play pause play reverse",
        },
        opacity: 0,
        x,
        y,
        duration: 0.2,
        ease: "cubic-bezier(0.16, 1, 0.3, 1)",
    });

    element.addEventListener("scrollTrigger.exit", () => animation.reverse());
};

const initStaggerAnimation = (elements) => {
    const offsets = {
        "slide-up": { x: 0, y: 15 },
        "slide-down": { x: 0, y: -15 },
        "slide-left": { x: 15, y: 0 },
        "slide-right": { x: -15, y: 0 },
    };

    const direction = Array.from(elements).find((el) =>
        el.classList.contains("slide-up" || "slide-down" || "slide-left" || "slide-right")
    );
    const { x, y } = offsets[direction];

    gsap.set(elements, { opacity: 0, x, y });
    gsap.to(elements, {
        scrollTrigger: {
            start: "top 80%",
            end: "top",
            trigger: elements[0],
            toggleActions: "play pause play reverse",
        },
        stagger: 0.1,
        opacity: 1,
        x: 0,
        y: 0,
        duration: 0.2,
        ease: "cubic-bezier(0.16, 1, 0.3, 1)",
    });
};



// =========================
// Loop items animation
// =========================
function animateElements(selector, context = document) {
    const elements = gsap.utils.toArray(selector, context).filter(el => !el.classList.contains('animated'));

    if (elements.length > 0) {
        isAnimating = true;

        gsap.to(elements, {
            opacity: 1,
            x: 0,
            y: 0,
            ease: 'power4.inOut',
            duration: .2,
            stagger: {
                each: 0.1, // Delay between each element
                onComplete: function () {
                    // Add 'animated' class to each element as it completes its animation
                    this.targets().forEach(target => {
                        target.classList.add('animated');
                    });
                },
            },
            onComplete: function () {
                isAnimating = false; // Reset the flag when animation completes
            },
        });
    }
}



// =========================
// Count Up Animation
// =========================
const countUp = (selector) => {
    const elements = document.querySelectorAll(selector);

    const isInView = (element) => {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    };

    const startCounter = (element) => {
        const target = parseInt(element.dataset.target.replace(/[.,]/g, ""));
        const step = target / 30;
        let current = 0;

        const interval = setInterval(() => {
            current += step;
            element.textContent = Math.min(current, target).toLocaleString();
            if (current >= target) clearInterval(interval);
        }, 50);
    };

    elements.forEach((element) => {
        if (isInView(element)) startCounter(element);
        else {
            element.setAttribute("data-target", element.textContent);
            element.textContent = "0";

            const onScroll = () => {
                if (isInView(element)) {
                    startCounter(element);
                    window.removeEventListener("scroll", onScroll);
                }
            };

            window.addEventListener("scroll", onScroll);
        }
    });
};



// =========================
// Offcanvas Animation
// =========================
const animateOffcanvas = (offcanvas) => {
    gsap.to(offcanvas, {
        y: offcanvas.classList.contains("expanded") ? "0%" : "-100%",
        duration: 0.5,
        ease: Power4.easeInOut,
    });
};



// =========================
// Email Encoding
// =========================
document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll('a[href^="mailto:"]').forEach((link) => {
        const email = link.getAttribute("href").substring(7);
        const encodedEmail = email
            .split("")
            .map((char) => `&#${char.charCodeAt(0)};`)
            .join("");

        link.setAttribute("href", `mailto:${encodedEmail}`);
        link.addEventListener("click", (e) => {
            e.preventDefault();
            window.location.href = `mailto:${email}`;
        });
    });
});



// =========================
// Set the initLoad Cookie to Track First Load
// =========================
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); 
        expires = "; expires=" + date.toUTCString(); 
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    console.log("Cookie Set: ", document.cookie); // Debugging output
}